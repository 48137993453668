.selectTargetImageSvgContainer {
    background: var(--background);
    width: 60px;
    height: 60px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectTargetImageSvg {
    position: relative !important;
    margin: 0 !important;
}