.uploadButton {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    width: 100%;
    height: 100%;
    
    /* color: #FFFFFF;
    background: rgb(57, 154, 183);
    border: 1px solid rgb(69, 59, 155); */
    box-sizing: border-box;
    border-radius: 4px;
    /* height: 30px; */
    cursor: pointer;
    /* font-size: 16px; */
    /* padding-top: 6px; */
    text-align: center;
}

.nextButton{
    position: absolute;
    left: 45.87%;
    right: 5.91%;
    top: 67.86%;
    bottom: 27.46%;

    background: #86A8E7;
    border: 1px solid #86A8E7;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
}

.videoFilterCardInput {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    padding-right: 10px;
}