.uploadFilterMainContainer {
    background: var(--background-light);
    border-radius: 5px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 30px;
}

.videoTooLong {
  color: red;
  margin-bottom: 20px;
}

.uploadFilterMainContainer > p {
    text-align: center;
}

.uploadedFilterContainer {
  height: 230px;
  /* margin: 0 30px; */
  margin-bottom: 15px;
}

.videoFormatContainer {
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */
    grid-template-columns: 1fr;
    grid-gap: 15px;
    align-content: stretch;
  }

  .uploadedVideoContainer {
    width: 100%;
    height: 100%;
    background: var(--background);
    border-radius: 5px;
    margin-top: 15px;
  }

  
.formatItem, .formatItem360 {
    justify-self: center;
    /* width: 102px; */
    height: 102px;
    width: 100%;
    min-height: 100px;
    background: var(--background);
    justify-self: stretch;
    align-self: stretch;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    transition: 0.2s;
    max-width: 120px;
    justify-self: center;
  }

.formatItem:hover {
    -webkit-box-shadow: 0px 0px 12px 1px var(--primary-color);
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

.formatItem.active {
    background-color: var(--primary-color);
}

  
.formatItem > p, .formatItem360 > p {
    font-size: 0.7rem;
    margin-top: 10px;
    color: var(--grey-light);
    text-align: center;
}
.formatItem360 {
  background-color: var(--primary-color);
  justify-content: space-between;
  /* max-width: 115px; */
  min-width: 110px;
}

.formatItem360 > p {
  margin-bottom: 15px;
}

.formatItem360 > input {
  text-align: center;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  background: var(--background);
  width: 100%;
  border: none;
  color: var(--white);
  border-radius: 5px;
  margin: 0;
  height: 50px;
}

.formatItem360 input::placeholder {
    color: var(--white);
}
  
.formatItem360 span {
    width: 5px;
    height: 5px;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: 33px;
}

.commingSoonContainer {
  position: absolute;
  width: 100%;
  height: 25px;
  bottom: 0;
  left: 0;
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
}

.commingSoonItem {
  color: var(--grey-dark) !important;
  
}



.commingSoonContainer > p {
  font-size: 10px;
  font-weight: 900;
}



.filterSetupContainer > p {
    text-align: center;

}
  
  
.filterSetupOptions {
    padding-top: 20px;
    /* margin-bottom: 20px; */
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 15px;
    align-content: stretch;
}

.filterNameContainer {
  /* padding-bottom: 30px; */
  padding-top: 20px;
}

.filterNameContainer > p {
    text-align: center;
    margin-bottom: 20px;
}

.filterNameContainer > input {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.filterNameInputs {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filterNameInputs input {
    width: 100%;
}

.filterNameInputs button {
    border-radius: 5px;
    padding: 0 30px;
    font-size: .9rem;
}

.disabled {
  background-color: rgb(65, 65, 65) !important;
  cursor: not-allowed;
}



.filterTransparentColor {
  width: 100%;
  margin-top: 20px;
}

.filterTransparentColor > p {
  text-align: center;
}

.filterTransparentInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.filterTransparentInputs > input {
  width: 47%;
}

.previewContainer {
  position: absolute;
  bottom: 0px;
  left: calc(-350px - 30px);
  width: 350px;
  height: 270px;
  background: var(--background-light);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 25px 1px #000000; 
box-shadow: 0px 0px 25px 1px #000000;
}

.previewContainerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.previewContainerHeader > h4 {
  font-size: 1rem;
}

.previewContainerHeader > div {
  position: absolute;
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 0;
}

.videoContainer {
  width: 100%;
  height: 90%;
  background: var(--background);
  border-radius: 5px;
  margin-top: 15px;
}

.videoContainer > video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.videoContainer > img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
}

.uploadedVideoContainer {
  width: 100%;
  height: 100%;
  background: var(--background);
  border-radius: 5px;
}

.uploadedVideoContainer > video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.uploadedVideoContainer > img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
}