@import url('https://fonts.googleapis.com/css2?family=Lalezar&family=Montserrat:wght@200;400;600&family=Orbitron:wght@400;600;900&family=Ubuntu:wght@300;400;700&display=swap');

:root {
  --background: #090C08;
  --background-light: #202024;
  --primary-color: #9801B0;
  --primary-color-hover: #2a746a;
  --grey-light: #E1E1E6;
  --grey-dark: #A5A5B0;
  --facebook: #4C77BC;
  --green: #4F9D69;
  --yellow: #FFE74C;
  --white: #F8F8F8;
  --google: #F0386B;
  --email: #F5A6E6;
}

a {
  text-decoration: none !important;
}

html {
  /* background: var(--background); */
  margin: 0 auto;
  max-width: 1044px;
  position: relative;
  min-height: 100%;
  min-height: 100vh;
  /* background: #090C08; */
  background: url("assets/perkLab_purple.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.App {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* background-color: var(--background); */
  
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  /* min-height: calc(100vh - 120px); */
  /* min-height: calc(100vh - 80px); */
  position: relative;
}

.firebaseui-title {
      font-size: 1.5rem;
    font-weight: normal;
    margin-top: 0;
    font-family: 'Lalezar', cursive;
}

.firebaseui-label {
  padding-right: 10px;
  width: 200px;
}
.firebaseui-id-submit {
  background-color: var(--primary-color) !important;
  margin-top: 20px;
}

.hidden {
  display:none;
}

.firebaseui-textfield {
  padding: 3px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  margin-bottom: 100px;
  margin-top: 200px;
}

button {
  border: none;
  border-color: transparent;
  border-radius: 5px;
  background-color: var(--primary-color);
  cursor: pointer;
  color: var(--white);
  font-family: 'Ubuntu', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
  transition: 0.2s;
}

button:hover{
  background-color: var(--primary-color-hover);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input {
  background-color: var(--background);
  border: none;
  height: 45px;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
  color: var(--grey-light);
  font-family: 'Ubuntu', sans-serif;
}

.mainSpinner {
  width: 100% !important;
  height: 93vh !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerCircle {
  width: 100px !important;
  height: 100px !important;
  color: var(--primary-color);
}

.Toastify__toast-container {
  margin-top: 100px;
}

#calendar {
  position: absolute;
  right: 0px;
  bottom: 23%;
  z-index: 99;
}

#step {
  margin-bottom: 40px;
  color: var(--grey-dark);
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

.rdrCalendarWrapper {
  background-color: var(--background) !important;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 12px 1px var(--background);
  box-shadow: 0px 0px 12px 1px var(--background);
  padding: 20px;
  right: 0;
}

.rdrCalendarWrapper,
.rdrDateDisplayWrapper {
  background-color: var(--background-light) !important;
  border-radius: 5px;

}

.rdrDayNumber span {
  color: white !important;
}

.rdrNextPrevButton,
.rdrNextButton {
  background-color: var(--background) !important;

}

.rdrPprevButton i {
  border-color: transparent var(--primary-color) transparent transparent !important;
}

.rdrNextButton i {
  border-color: transparent transparent transparent var(--primary-color) !important;
}

.rdrDateInput {
  background-color: var(--background) !important;
}


.ReactModal__Overlay {
  z-index: 20000 !important;
  background-color: rgba(0, 0, 0, 0.8)!important;
}

.ReactModal__Content {
  border: none !important;
  background-color: var(--background-light) !important;
  color: var(--white);
  max-width: 500px;
  padding: 40px !important;
  text-align: center;
  position: relative;
  
}

.ReactModal__Content > header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content > header > h2 {
  font-family: 'Lalezar', sans-serif;
  margin-top: 13px;
  margin-left: 20px;
  margin-right: 20px;
}

.ReactModal__Content > p {
  margin-top: 20px;
  font-size: 1.2rem;
  color: var(--grey-dark);
  line-height: 30px;
  
}

.ReactModal__Content > footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.ReactModal__Content > footer > button {
  width: 140px;
  border-radius: 5px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.input-group-app{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.App-header {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  width: 150px;
  
}

#sidebar-wrapper{
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  max-width: 250px;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.horizontal-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.vertical-center {
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  padding-top: 5%;
}

.ReactCrop__image {
  max-height: 330px;
}

/* img {
    max-width: 60%;
    max-height: 100%;
} */

.portrait {
    height: 320px;
    width: auto;
    padding: 10px;
}

.landscape {
    height: 320px;
    width: auto;
    padding: 10px;
}

.square {
    height: 290px;
    width: auto;
    padding: 10px;
}

.video-reponsive-landscape {
  width: 100%;
  max-height: 100%;
}

.video-reponsive-portrait {
  max-width: 100%;
  height: 100%;
}

.experience-controls-md {
  width: 200px;
  float: left;
  margin-left: 10px;
  margin-top: 5px; 
}

.experience-controls-sm {
  width: 95px;
  float: left;
  margin-left: 10px;
  margin-top: 5px; 
}

@media (max-width: 420px){
  #calendar {
    transform: scale(.8);
    right: -40px !important;
    bottom: 10% !important;
  }
}