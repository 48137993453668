
.productContainer {
    background-color: var(--background-light);
    width: 240px;
    min-height: 320px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    position: relative;
    margin: 20px;
}

.productContainer > span {
    width: 100%;
    height: 3px;
    background: var(--primary-color);
}

.productContainer > ul {
    width: 100%;
    padding: 0;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 60px;
}

.productContainer > ul  > li {
    margin-bottom: 6px;
}

.productItem {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: .9rem;
}

.productItem > svg {
    margin-right: 10px;
}

.productContainer > button {
    font-family: 'Montserrat', sans-serif;
    width: calc(100% - 40px);
    margin: 20px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.priceTage {
    position: absolute;
    top: -20px;
    right: -25px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.priceTagNumber {
    position: absolute;
    top: 35%;
    left: 53%;
    transform: translate(-50%, -50%)
}

.priceTagNumber.month  {
    left: 39%;
}

.priceTagNumber > span {
    position: absolute;
    font-size: 15px;
    bottom: 5px;
    right: -26px;
}