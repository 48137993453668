.targetImageCard {
    width: 100%;
    height: 100px;
    background: var(--background-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
}

.targetImageCardPictureAndName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.targetImageCardPictureAndName > img {
    height: 100%;
    width: 80px;
    border-radius: 5px;
    object-fit: cover;
}

.targetImageCardPictureAndName > p {
    margin-left: 20px;
}

.targetImageCardStatus {
    display: flex;
}

.delete-button {
    /* width: 35px; */
    height: 35px;
    border-radius: 5px;
    font-size: .8rem;
    margin-left: 20px;
}

.uploading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-dark);
}

.uploading > p, .uploading {
    font-size: .9rem;
}

.targetImageCardPictureContainer {
    width: 80px;
    height: 80px;
    background: var(--background);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.targetPicture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    min-width: 80px;
}

