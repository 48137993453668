.logoAndNameContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.logoAndNameContainer aside {
    text-align: left;
}

.logoAndNameContainer aside img {
    max-width: 90%;
}

.logoAndNameContainer main {
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
}

.logoAndNameContainer main h1 {
    font-family: 'Lalezar', cursive;
    font-weight: normal;
    margin-bottom: 30px;
}

.logoContainer {
    text-align: center;
    /* margin-right: 15px; */
}

.logoAndNameContainer .topContainer {
    background-color: var(--background-light);
    border-radius: 5px;
    width: 100%;
    /* width: 340px; */
    /* height: 210px; */
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}

.logoAndNameContainer h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 30px;
}

.logoAndNameInputsContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
}

.logoAndNameInputsContainer .uploadLogo {
    min-width: 120px;
    height: 120px;
    background-color: var(--background);
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.uploadLogo img {
    border-radius: 5px;
    width: 120px;
    height: 120px;
    object-fit: contain;
}


.logoAndNameInputsContainer .rightInputs {
    /* height: 100%;
    width: calc(100% - 107px - 30px); */
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    flex-direction: column;
    text-align: center;
    /* margin-left: 15px;
    margin-right: 15px; */
}

.rightInputs > p,
.logoContainer > p {
    margin-bottom: 15px;
}

.logoAndNameInputsContainer .uploadLogo .uploadImageButton {
    background-color: transparent;
    /* position: relative; */
    /* border-radius: 5px;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 3px;
    right: 3px;
    color: var(--white);
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center; */
}

.swithLogo {
    background-color: var(--primary-color);
    border-radius: 5px;
    left: auto !important;
    top: auto !important;
    right: -5px;
    bottom: -5px;
}

.logoAndNameInputsContainer .uploadLogo .uploadImageButton svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    pointer-events: none;
}

.logoAndNameInputsContainer .rightInputs input {
    height: 40px;
    border-radius: 5px;
    text-align: center;
    background-color: var(--background);
    border: none;
    color: var(--grey-light);
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
}

.logoAndNameInputsContainer .rightInputs .colorPicker {
    /* background-color: var(--primary-color);
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    border: none;
    width: 100%;
    border-color: transparent;
    outline: none; */
    width: 100%;
    height: 100%;
    position: relative;
}

.colorPicker p {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    width: 140px;
}

.rightInputs {
    position: relative;
}

#colorPickerTextInput {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 25px;
    height: 30px;
}

#colorPickerInput {
    height: 20px;
    width: 100%;
    border-color: transparent;
    outline: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
}

.colorPickerContainer {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--background);
    position: relative;
    cursor: pointer;
    transition: 0.2s;
}

.colorPickerContainer:hover {
    -webkit-box-shadow: 0px 0px 12px 1px var(--primary-color);
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

label {
    margin-bottom: 0 !important;
}

.colorPickerContainer > p {
    font-size: 1rem;
    margin-top: 15px;
}

.logoAndNameContainer .bottomContainer {
    background-color: var(--background-light);
    margin-top: 15px;
    border-radius: 5px;
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logoAndNameContainer > main > p {
    color: var(--grey-dark);
    text-align: center;
    margin-bottom: 30px;
}

.comboInputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
}


.comboInputContainer input {
    border-radius: 5px;
    text-align: center;
    background-color: var(--background);
    border: none;
    color: var(--grey-light);
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    height: 40px;
    width: calc(100% - 110px - 30px);
}

.comboInputContainer button {
    background-color: var(--primary-color);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    height: 100%;
    width: 110px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
    color: var(--white)
}

.comboInputContainer button:hover {
    background-color: var(--primary-color-hover)
}

.createExpNextButton {
    margin-top: 25px;
    width: 130px;
    border-radius: 20px;
    
}

.datePickerContainer,
.rightInputs,
.logoContainer {
    margin: 0 5px;
    margin-bottom: 20px;
    
}

.colorButtonContainer {
    position: relative;
}

.premiumIcon {
    position: absolute;
    top: -6px;
    right: -3px;
}

.datePickerContainer > p {
    margin-bottom: 15px;
    text-align: center;
}

.datePickerButton {
    position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     background-color: var(--background);
     border-radius: 5px;
     width: 120px;
     height: 120px;
     cursor: pointer;
     transition: 0.2s;
}

.datePickerButton:hover {
    -webkit-box-shadow: 0px 0px 12px 1px var(--primary-color);
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

.textsContainer {
    font-size: 0.7rem;
    text-align: center;
    margin-top: 10px;
}

.textsContainer > p {
    color: var(--grey-dark);
}

.textsContainer > span {
    font-size: 1rem;
}

.personalizeFooter {
     display: flex;
     justify-content: center;
     align-items: center;
     padding-bottom: 40px;
     margin-top: 30px;
     cursor: pointer;
     transition: 0.2s;
}

.personalizeFooter:hover {
    transform: translateY(-5px);
}

.personalizeFooter > p {
    margin-left: 10px;
    color: var(--grey-dark);
}

.personalizeFooter > p > strong {
    color: var(--primary-color);
    font-weight: 900;
    font-size: 1.1rem;
}

.dateInputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.durationInput {
    width: 40px;
    height: 30px;
    outline: none;
    font-size: 1.2rem;
    margin-top: 8px;
    text-align: center;
    position: relative;
    /* background-color: red; */
}

.dateInputContainer > span {
    /* position: absolute;
    right: 25px;
    bottom: 30px; */

}

.datePickerButton > span {
    color: var(--grey-dark);
}

@media (max-width: 940px) {
    .logoAndNameContainer main {
        padding: 0 10%;
    }
    .logoAndNameContainer aside img {
        display: none;
    }
}

@media (max-width: 640px) {
    .logoAndNameContainer main {
        padding: 0;
    }
}
@media (max-width: 480px) {
    .logoAndNameInputsContainer, .comboInputContainer {
        width: 100%;
    }
    .logoAndNameInputsContainer .rightInputs {
        /* width: calc(100% - 107px - 15px); */
        /* margin: 0 15px; */
    }
    .logoAndNameContainer h2 {
        font-size: 1rem;
        margin-top: 0;
        margin-bottom: 20px;
        margin-top: 0;
    }
    .logoAndNameInputsContainer .uploadLogo {
        /* margin-left: 15px; */
    }

    /* .logoAndNameContainer .topContainer {
        height: 200px;
    } */

    .logoAndNameContainer .bottomContainer  {
        height: 140px;
    }

    .comboInputContainer input {
        width: calc(100% - 110px - 15px);
    }

    
    .logoAndNameContainer main h1 {
        font-size: 1.4rem;
    }

    .logoAndNameContainer > main > p {
        font-size: .8rem;
    }

    .personalizeFooter > p,
    .personalizeFooter > p > strong {
        font-size: .8rem;
    }
    .createExpNextButton {
        font-size: 1rem !important;
        padding: 0 30px !important;
        margin-bottom: 20px !important;
        
    }
}