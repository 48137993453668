/* .logoUploadButton {
   
    min-height: 40px;
    color: #FFFFFF;
    background: rgb(57, 154, 183);
    border: 1px solid rgb(69, 59, 155);
    box-sizing: border-box;
    border-radius: 4px;
    height: 30px;
    cursor: pointer;
    font-size: 16px;
    padding-top: 6px;
    margin-top: 5px;
    width: 100%;
    text-align: center;
} */


.logoUploadButton {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: var(--white);
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.logoUploadButton:hover {
    -webkit-box-shadow: 0px 0px 12px 1px var(--primary-color);
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}