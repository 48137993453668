.logginPageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.loginButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logginPageContainer::before {
    content: '';
    width: 80vw;
    height: 60vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    border-radius: 50%;
    z-index: -1;
    background: radial-gradient(51.74% 51.74% at 50% 50%, rgba(126, 65, 165, 0.19) 0%, rgba(126, 65, 165, 0) 100%);
}


.logginPageContainer > h1 {
    font-family: 'Lalezar', cursive;
    font-weight: normal;
    margin-bottom: 30px;
    text-align: center;
    margin-bottom: 30px;
}

.logginPageContainer > h1 > strong {
    font-weight: normal;
    color: var(--primary-color);
}

.logginPageContainer > main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--background-light);
    padding: 40px;
    width: 400px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.logginPageContainer > main > input {
    margin-bottom: 20px;
    width: 100%;
}

.loginAndRegister,
.googleAndFacebook {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.loginAndRegister > button,
.googleAndFacebook > button,
.logginPageContainer > main > button {
    width: 140px;
    height: 40px;
    border-radius: 20px;
    font-size: .9rem;
}

.registerButton {
    background: transparent;
    border: 1px solid var(--primary-color);
}

.registerButton:hover {
    border: 1px solid var(--primary-color-hover);
}

.loginWith,
.orGuest {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.loginWith > span,
.orGuest > span {
    width: 30%;
    height: 1px;
    background: var(--grey-dark);
}

.orGuest > span {
    width: 45%;
}

.loginWith > p, 
.orGuest > p {
    margin: 0 20px;
    color: var(--grey-dark);
}


.logginPageContainer > main > button {
    width: 100%;
}

.logInWithGoogle, 
.logInWithFacebook {
    background: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media (max-width: 460px) {
    .logginPageContainer {
        margin-top: 40px;
    }
    
    .logginPageContainer > h1 {
        font-size: 1.8rem;
    }

    .logginPageContainer > main {
        width: 100%;
        margin: 15px;
        padding: 30px;
    }

    .loginAndRegister > button,
    .googleAndFacebook > button,
    .logginPageContainer > main > button {
        width: 110px;
        font-size: .8rem;

    }
    .logginPageContainer > main > button {
        width: 100%;
    }

    .loginWith > p, 
    .orGuest > p {
        font-size: .8rem;
    }

    .loginWith > span {    
        width: 26%;
    }
}