.experiencesContainer > header {
    padding-top: 129px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin-bottom: 30px;
}

.experiencesContainer h1 {
    font-family: 'Lalezar', cursive;
    margin-top: 0;
    font-weight: normal;
    font-size: 2rem;
    margin-bottom: 0;

}


.experienceCardBody > aside > button {
    width: 100px;
    height: 42px;
    background-color: var(--primary-color);
    color: var(--white);
    border-radius: 5px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    transition: .2s;
    margin-left: 20px;
}


.experienceCardBody > aside > button:hover {
    background-color: var(--primary-color-hover);
}

.experiencesContainer p {
    color: var(--grey-dark);
}

.experiencesContainer p span {
    font-family: 'Orbitron', sans-serif;
    color: var(--white);
}

.listCreateButton {
    width: 120px;
    height: 40px;
    border-radius: 20px;
}

.loadingListOfExperiences {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.noExperiences {
    margin-top: 0px;
    padding-top: 100px;
    min-height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
}


.noExperiences::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 500px;
    user-select: none;
    z-index: 1;
    background: radial-gradient(
      51.74% 51.74% at 50% 50%,
      rgba(126, 65, 165, 0.19) 0%,
      rgba(126, 65, 165, 0) 100%
    );
  }

.noExperiences > img {
    max-width: 35vw;
    height: auto;
    padding-right: 80px;
}

.createFirstExperience {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 100;
}

.createFirstExperience > h2 {
    font-family: 'Lalezar', sans-serif;
    font-size: 2.5rem;
    text-align: center;
}

.createFirstExperience > h2 > strong {
    color: var(--primary-color);
    font-weight: normal;
}

.createFirstExperience > button {
    width: 250px;
    height: 60px;
    border-radius: 30px;
    margin-top: 60px;
}


.experienceCardsContainer {
    padding-bottom: 100px;
    width: 100%;
    min-height: calc(100vh - 320px);
    margin: 0 auto;
}

.experienceCardsContainer > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.experienceCardsContainer > h2 {
    text-align: center;
    margin: 30px 0;
    font-size: 1.5rem;
}

.experienceListContainer {
    width: 60%;
    margin: 0 auto;
}

.experienceCardBody {
    background: var(--background-light);
    border-radius: 5px;
    margin-bottom: 10px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    transition: 0.2s;
}

.experienceCardBody:hover {
    transform: translateX(5px);
}

.experienceCardBody p {
    color: var(--white);
    width: 60%;
}

.experienceCardBody > aside {
    display: flex;
    justify-content: center;
    align-items: center;
}

.experienceCardBody > aside > button {
    border-radius: 5px;
    width: 100px;
}

.noExperiences {
    width: 100%;
    justify-content: space-around;
    
}

.noExperiences > aside {
    text-align: center;
    position: relative;
    z-index: 100;
}

.noExperiences > aside > h1 {
    font-size: 2.2rem;
    margin-bottom: 50px;
}

.noExperiences > aside > h1 > strong {
    font-weight: normal;
    color: var(--primary-color);
}

.noExperiences > aside > a > button {
    width: 240px;
    height: 50px;
    border-radius: 25px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: 0.2s;
}

.noExperiences > aside > a > button:hover {
    background: var(--primary-color-hover);
}

@media (max-width: 940px) {
    .experienceCardsContainer {
        width: 100%;
    }

    .experienceCardBody > p {
        word-break: break-word;
        margin-right: 20px; 
    }

    .experienceCardBody > aside > button {
        padding: 0 20px;
        width: auto;
        margin-left: 8px;
    }

    .noExperiences > img {
        display: none;
    }
    
    .experienceListContainer {
        width: 100%;
        margin: 0;
    }

}

@media (max-width: 540px) {

    .experiencesContainer h1 {
        font-size: 1.5rem;
    }

    .experiencesContainer button {
        font-size: .8rem;
        width: auto;
        padding: 0 30px;
        
    }

    .experienceCardBody {
        padding: 20px;
    }

    .experiencesContainer p {
        font-size: .8rem;
    }

    .experienceCardsContainer > h2 {
        font-size: 1rem;
    }
    
    .createFirstExperience > h2 {
        font-size: 1.4rem;        
    }
    .createFirstExperience > button {
        font-size: 1rem;
    }
}