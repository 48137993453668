.route {
    margin-top: 100px;
    padding-bottom: 0;
    position: relative;
    z-index: 10;
    min-height: calc(100vh - 160px);
}

.spinnerCircle {
    margin-top: 80px;
}
