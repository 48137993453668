.errorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.errorContainer > aside {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorContainer > main {
    width: 50%;
}

.errorContainer > main > h1 {
    font-family: 'Lalezar', cursive;
    color: var(--primary-color);

}

.errorContainer > main > p {
    font-family: 'Ubuntu', sans-serif;
    color: var(--grey-dark);
    font-size: 1.3rem;
    margin-top: 50px;
}

.errorGoBack {
    border-radius: 20px;
    padding: 0 40px;
    margin: 0 auto;
    margin-top: 50px;
}
