
.experienceNameContainer {
    position: relative;
    height: 50px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.experienceNameContainer > p {
    color: var(--grey-dark);
    margin-top: 15px;
    font-size: .9rem;
}

@media (max-width: 420px) {
    .experienceNameContainer > p {
        font-size: .8rem;
        text-align: center;
    }
}