.footerContainer {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2000;


    
    position: relative;
    bottom: 10px;
    
}

.footerContainer svg {
    margin: 0 12px;
}

.footerContainer p {
    font-family: 'Montserrat', sans-serif;
    color: var(--grey-dark);
}

@media screen and (max-width: 420px) {
    .footerContainer {
        position: relative;
        bottom: 10px;
    }
    .footerContainer p {
        font-size: 0.7rem;
    }
    
}