.subscriptionPageContainer {
    min-height: calc(100vh - 220px);
    width: 100%;
    margin: 30px 0;
    font-family: 'Lalezar', sans-serif;
    text-align: center;
}

.subscriptionPageContainer::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 600px;
    -webkit-user-select: none;
    user-select: none;
    z-index: 0;
    background: radial-gradient( 51.74% 51.74% at 50% 50%, rgba(126, 65, 165, 0.19) 0%, rgba(126, 65, 165, 0) 100% );
}

.subscriptionPageContainer > h1  {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
}

.subscriptionPageContainer > h2  {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
}

.subscriptionPageContainer > main {
    position: relative;
    z-index: 10;
    width: 100%;    
    min-height: calc(100vh - 480px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 40px; */
}

.subscriptionPageContainer > h1 > strong {
    font-weight: normal;
    color: var(--primary-color);
}

.subscriptionPageContainer > p {
    font-family: 'Ubuntu', sans-serif;
    color: var(--grey-dark);
    text-align: center;
    margin: 0 150px;
    margin-bottom: 10px;
    
}

.subscriptionPageContainer > button {
    font-weight: bold;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    border-radius: 20px;
    padding: 0 30px;
    position: relative;
    z-index: 100;
    margin-bottom: 40px;
}

@media (max-width: 850px) {
    .subscriptionPageContainer > p {
        margin: 0;
        margin-bottom: 20px;
    }
}
