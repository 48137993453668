body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0) !important;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(46, 46, 46); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color); 
    /* background-image: url("assets/perkLab_purple.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  transition: 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-hover); 
    /* background-image: url("assets/perkLab_purple.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
}


@media screen and (max-width: 1044px) {
    html {
      padding: 0 40px;
    }

    .App {
      padding: 40px;
    }
}


@media screen and (max-width: 480px) {
    html {
      padding: 0 15px;
    }

    .App {
      padding: 15px;
    }
    .Toastify__toast-container--top-center, .Toastify__toast-container--top-left, .Toastify__toast-container--top-right {
      top: 120px !important;
      width: 80vw !important;
      right: 10px !important;
      left: auto !important;
    }
}