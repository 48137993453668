.mainTitle {
    text-align: center;
    margin-top: 120px;
    font-family: 'Lalezar', cursive;
    font-weight: normal;
    font-size: 2rem;
}


.experienceNameContainer > p {
    color: var(--grey-dark);
    margin-top: 15px;
    font-size: .9rem;
}

.feedbackTargetImage {
    margin-bottom: 30px !important;
}

.experienceNameInputContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.experienceNameInputContainer > svg {
    position: absolute;
    bottom: 20px;
    right: 25px;
    /* display: none; */
    opacity: 0;
    transition: 0.2s;
}

.inputExperienceName:focus ~ svg {
    opacity: 1;
    
}

.experienceNameInputContainer:hover > svg {
    opacity: 1;
}

.inputExperienceName {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-top: 10px;
    font-family: 'Lalezar', sans-serif;
    color: var(--white);
    font-size: 2rem;
    outline: none;
    border: 1px solid var(--primary-color);
    background: transparent;
    
}

.inputExperienceName::placeholder {
    font-family: 'Lalezar', sans-serif;
    color: var(--grey-light);
    font-size: 2rem;
}

.createExperienceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: calc(100vh - 160px);
    margin-top: 80px;
    margin-bottom: 20px;
}

.logoAndColorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.createExperienceContainer.active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 109px;
    min-height: calc(100vh - 100px);
}


.logoAndDatesContainer, .targetImageContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;

}

.targetImageContainer {
    margin-top: 0;
}

.imageTargetText > h2 {
    color: var(--white);
    font-family: 'Lalezar', sans-serif;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
}

.imageTargetText > h3 {
    color: var(--grey-light);
    font-family: 'Lalezar', sans-serif;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    font-size: 24px;
}

.logoAndDatesContainer main {
    width: 100%;
    margin-bottom: 0;
}

.logoAndDatesContainer > img {
    width: 50%;
    padding-right: 50px;
}

.targetImageInfo {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.targetImageInfo > img {
    width: 60%;
    margin: 0 auto;
}

.targetImageInfo > aside {
    margin-top: 30px;
}

.targetImageDataContainer {
    width: 50%;
    height: 100%;
    text-align: center;
}

.targetImageDataContainer > h4 {
    font-size: 1rem;
    margin-bottom: 40px;
    color: var(--grey-light);
    line-height: 30px;
    font-family: 'Lalezar', cursive;
    font-size: 1.3rem;
}

.targetImageDataContainer > p {
    margin-bottom: 60px;
}

.targetImageDataContainer > h4 >strong {
    color: var(--primary-color);
}

.targetImageDataContainer > h1, .filtersDataContainer > h1 {
    text-align: center;
    font-size: 2rem;
    color: var(--grey-light);
    font-family: 'Lalezar', cursive;
    font-weight: normal;
}

.targetImageDataContainer > h2, .filtersDataContainer > h2 {
    text-align: center;
    font-size: 1rem;
    margin: 20px 0;
    color: var(--grey-dark);
}

.targetImageFieldsContainer {
    background: var(--background-light);
    width: 100%;
    border-radius: 5px;
    padding: 30px;
}

.targetImageDataContainer > aside {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.targetImageDataContainer > aside > img {
    width: 20%;
}

.imageTargetText {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    color: var(--grey-dark);
    line-height: 30px;
    margin: 0 15px;
    margin-top: 50px;
}

.imageTargetText strong {
    color: var(--white);
}

.imageTargetText > p:last-child {
    margin-top: 30px;
}

.targetImageStepButtonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

.targetImageStepButtonsContainerNext {
    display: flex;   
    align-items: center;
    margin-top: 30px;
    width: 100%;
    justify-content: flex-end;
}

.targetImageStepButtonsContainer > button,
.targetImageStepButtonsContainerNext > button {
    border-radius: 20px;
    padding: 0 25px;
    margin-bottom: 40px;
}



.filtersContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 100px; */
    margin-top: 60px;
}

.filtersImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 50%;
    height: 100%; 
}

.filtersImageContainer.withVideos {
    /* flex-direction: column-reverse; */
    justify-content: space-between;
}

.filtersImageContainer.withVideos > img {
    margin-top: 30px;
}

.filtersImageContainer > img {
    width: 60%;
    margin: 0 auto;
}   

.filtersDataContainer {
     width: 50%;
     height: 100%;
     position: relative;
     text-align: center;
}


.filtersDataContainer > h4 {
    color: var(--grey-light);
    line-height: 30px;
    font-family: 'Lalezar', cursive;
    font-size: 1.3rem;
    margin-bottom: 30px;
}


.filtersDataContainer > p {
    text-align: center;
    margin: 15px 0;
}

.filterListContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.filterListContainer > p {
    text-align: center;
    font-size: 1rem;
    margin: 20px 0;
    color: var(--grey-dark);
}

.publishContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    z-index: 1000;
    /* padding-bottom: 100px; */
}

.publishContainer::before {
    content: '';
    width: 80vw;
    height: 60vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    background: red;
    border-radius: 50%;
    z-index: -1;
    background: radial-gradient(51.74% 51.74% at 50% 50%, rgba(126, 65, 165, 0.19) 0%, rgba(126, 65, 165, 0) 100%);
}


.publishContainer > img {
    width: 50%;
}

.publishContainer > main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* height: 400px; */
}

.publishContainer > main > h4, 
.publishContainer > main > h4 > strong {
    font-family: 'Lalezar', cursive;
    font-weight: normal;
    font-size: 2.2rem;
    margin-bottom: 20px;
    text-align: center;
}


.publishContainer > main > p:last-of-type {
    font-weight: normal;
    font-size: 0.9rem;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
}

.publishContainer > main > h4 > strong {
    color: var(--primary-color);
}

.publishContainer > main > button {
    width: 200px;
    height:200px;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: var(--background-light);
}

.publishContainer > main > button > svg {
    margin-left: 5px;
}

.publishContainer > main > button > h4 {
    margin-bottom: 0;
    margin-top: 30px;
    
}

.publishContainer > main > button:hover {
    background: var(--primary-color);
    -webkit-box-shadow: 0px 0px 12px 1px var(--primary-color);
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

.boBackButton {
    margin-top: 40px !important;
    height: 60px !important;
}

.publishContainer > main  > p {
    margin-bottom: 30px;
    font-size: 1.2rem;
    color: var(--grey-dark);
}

.successContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 70px;
    z-index: 1000;
    padding-bottom: 100px;
}

.successContainer::before {
    content: '';
    width: 80vw;
    height: 60vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    background: red;
    border-radius: 50%;
    z-index: -1;
    background: radial-gradient(51.74% 51.74% at 50% 50%, rgba(126, 65, 165, 0.19) 0%, rgba(126, 65, 165, 0) 100%);
}

.successContainer > img {
    width: 40%;
    padding-right: 40px;
}

.successContainer > aside {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;
}

.successContainer > aside > h1 {
    text-align: center;
    font-family: 'Lalezar', cursive;
    font-weight: 300;
    font-size: 3rem;
    margin-bottom: 0px;
}

.successContainer > aside > h2,
.successContainer > aside > h3 {
    text-align: center;
    font-family: 'Lalezar', cursive;
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 30px;
}

.successContainer > aside > h2 {
    font-family: 'Lalezar', cursive;
    font-size: 1.6rem;
    margin-bottom: 30px;
}

.successContainer > aside > h1 > strong {
    color: var(--primary-color);
}

.successContainer > aside > a > img {
    margin: 20px 0;
    width: 180px;
    transition: 0.2s;
}

.successContainer > aside > a > img:hover {
    transform: scale(1.1);
}

.successContainer > aside > a > h3 {
    font-size: 1rem;
    font-weight: 900;
    margin-bottom: 80px;
    margin-top: -30px;
}

.successButtonsContainer {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
        height: 50px;
}

.successButtonsContainer > button, .homeButton {
    width: 130px;
    height: 40px;
    border-radius: 20px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.successContainer > aside > p {
    text-align: center;
    margin-top: 20px;
    line-height: 40px;
}

.successContainer > aside > p > strong {
    color: var(--primary-color);
}

a {
    color: var(--white) !important;
}

.uploadingHeader {
    text-align: center;
}

.uploadingSpinner {
     width: 100%;
     height: 200px;
     display: flex;
     justify-content: center;
     align-items: center;
}

.publishButtonsContainer {
    max-width: 100%;
    border-radius: 5px;
    background-color: var(--background-light);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 30px;
    flex-wrap: wrap;
}

.pubishOption {
    width: 120px;
    height: 120px;
    background-color: var(--background);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    flex-direction: column;
    text-align: center;
    margin: 10px;
    font-weight: normal;
    position: relative;
}

.pubishOption.disabled > svg > path,
.pubishButton.disabled > svg > path  {
    fill: white;
}

.premiumIcon > path  {
    fill: rgb(187, 169, 52) !important;

}

.pubishButton {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--background);
    font-weight: normal;
    border: 1px solid var(--primary-color);
}

.pubishOption:hover,
.pubishButton:hover {
    background: var(--primary-color);
    -webkit-box-shadow: 0px 0px 12px 1px var(--primary-color);
    box-shadow: 0px 0px 12px 1px var(--primary-color);
}

.disabled:hover {
    background: var(--primary-color);
    -webkit-box-shadow: 0px 0px 12px 1px transparent;
    box-shadow: 0px 0px 12px 1px transparent;
    cursor: not-allowed;
}

.pubishOption:hover > svg > path,
.pubishOption:hover > .pubishButton > svg > path {
    fill: var(--white);
}

.pubishOption:hover > .premiumIcon > path  {
    fill: rgb(187, 169, 52);;
}


.pubishOption > p, .pubishButton > p {
    margin-top: 10px;
    font-size: .8rem;
    margin: 10px;
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
}

.maximumOfExperiences {
    margin: 45px 0px !important;
    text-align: center !important;
    font-weight: bold;
    font-size: 1.1rem;
    color: #F0386B !important;
}

.maximumOfExperiencesMail {
    color: var(--primary-color) !important;
}


.publishSpinner {
    color: var(--grey-dark);
}

.errorLoading {
    font-size: .8rem !important;
    margin-top: 15px;
}
.errorLoading > a {
    color: var(--primary-color) !important;
    font-weight: bold !important;
    margin-left: 5px;
}

.createExperienceSuccessContainer {
    margin-top: -100px;
}

@media (max-width: 945px) {
    .logoAndDatesContainer > img {
      display: none;
      width: 100%;
    }

    .logoAndDatesContainer main, 
    .targetImageDataContainer,
    .filtersContainer,
    .logoAndDatesContainer, 
    .targetImageContainer,
    .filtersDataContainer {
        width: 100%;
        flex-direction: column-reverse;
        margin-top: 50px;
        margin-bottom: 0;
    }

    .createExperienceContainer {
        justify-content: center;
    }

    .filtersDataContainer {
        margin-top: 30px;
    }

    .logoAndDatesContainer main {
        flex-direction: column;
    }

    .successContainer > aside {
        width: 100%;
    }


    .targetImageInfo, .filtersImageContainer {
        /* display: none; */
        width: 100%;
        max-width: 100%;
    }

    .previewContainer {
        right: 20px;
        bottom: 100px;
        left: auto;
    }

    .publishContainer > img,
    .successContainer > img {
        display: none;
    }

    .successContainer {
        margin-top: 20px;
    }


    .publishContainer > main > h4 {
        text-align: center;
    }
  }

  @media (max-width: 710px) {
    .successContainer > aside {
        width: 100%;
    }
  }

  @media (max-width: 530px) {
    .targetImageDataContainer {
        margin-top: 0px;
    }
    .targetImageStepButtonsContainer {
        flex-wrap: wrap;

    }
    .targetImageStepButtonsContainer > button {
        font-size: 1rem;
        padding: 0 30px;
        margin-bottom: 20px;
    }
    .targetImageStepButtonsContainerNext > button {
        font-size: 1rem;
        padding: 0 30px;
        margin-bottom: 20px;
    }
    
    .filtersContainer {
        margin-top: 0px;
    }
  }

  @media (max-width: 420px) {
    .filterNameInputs button {
        padding: 0 20px;
        font-size: .7rem;
    }
    
    .previewContainer {
        right: 20px;
        bottom: 100px;
        left: auto;
        width: 90%;
        height: 250px;
    }

    .uploading > p {
        display: none;
    }

    .experienceNameContainer > p {
        font-size: .8rem;
        text-align: center;
    }

    .inputExperienceName {
        height: 50px;
        font-size: 1.5rem;
        
    }
    
    .inputExperienceName::placeholder {
        font-size: 1.5rem;
    }
    .experienceNameInputContainer > svg {
        bottom: 13px;
    }

    .targetImageDataContainer > h4 {
        font-size: .9rem;
        margin-bottom: 40px;
        font-weight: bold;
        color: var(--grey-light);
        line-height: 30px;
    }
    
    
    .publishContainer > main > h4,
    .publishContainer > main > h4 > strong {
        font-size: 1.4rem;
    }
    

    .createExpNextButton {
        font-size: .7rem;
        padding: 0;
        width: auto;
        padding: 0 20px;
    }
    .successContainer {
        margin-top: 40px;
        padding-bottom: 0;
    }

    .successContainer > aside > h1 {
        font-size: 1.6rem;
    }

    .successContainer > aside > p {
        font-size: .8rem;
        line-height: 25px;
        margin-top: 35px;

    }
    .successButtonsContainer > button, .homeButton {
        width: auto;
        padding: 0 20px;
        font-size: .7rem;
    }

    .successButtonsContainer {
        margin-top: 40px;
    }

    .successContainer > aside > a > img {
        margin-top: 40px;
        height: 150px;
        width: 150px;
    }
    .successContainer > aside > a > h3 {
        margin-bottom: 0px;
    }
  }
  